import { Link } from "react-router-dom"
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import { useState, useRef, useCallback, useEffect } from "react";
import { Divider } from 'primereact/divider';

import { InputText } from 'primereact/inputtext';
import { debounce, simplify, balanceFormatter } from "helpers/functions";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import EditPartnerModal from "./modals/EditPartnerModal";
import DeletePartnerModal from "./modals/DeletePartnerModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import PartnerExportCSV from "./exports/PartnerExportCSV";
import TransactionHistoryModal from "./modals/TransactionHistoryModal";
import usePartner from "hooks/usePartner";
const UserPartnerTable = ({ searchId, datas, loading, totalRecords, lazyState, setLazyState }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const items = Array.from({ length: 5 }, (v, i) => i);
    const [search, setSearch] = useState(searchId || '');
    const { entityList, sectorList } = usePartner();
    const delay = 1000;
    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value;
        setLazyState(_filters)
    }
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState

    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const totalAmount = (data) => {
        return <TransactionHistoryModal data={data} partnerLazyState={lazyState} />
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditPartnerModal partner={data} />
                </div>
                {/* <div>
                    <DeletePartnerModal partner={data} />
                </div> */}
            </div>
        )
    }

    const partnerName = (data) => {
        return <Link to={`/partner-overview/${data?.name}`}> {data?.name} </Link>
    }

    const referralLink = (data) => {
        return <Link to={'/referrals'} state={data._id}> {data.referral_codes} ({data.active_referral_codes} active) </Link>
    }

    const promotionLink = (data) => {
        return <Link to={'/promotions'} state={data._id}> {data.promotions} ({data.active_promotions} active) </Link>
    }
    const userLink = (data) => {
        return <Link to={'/userlist'} state={data.referralList}> {data.signups} </Link>
    }
    const roundedVol = (data) => {
        return simplify(data.totalVolume)
    }
    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setLazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    const getAbbreviation = (data) => {
        const entity = entityList.find((item) => item.value === data.type);
        const sector = sectorList.find((item) => item.value === data.sector_type);
        const entityName = entity?.abbr??data.type;
        const sectorName = data.sector_type ? sector?.name??data.sector_type:"";
        return `${entityName} ${sectorName?`(${sectorName})`:""}`;

    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-6">
                        <h5>Partner Information</h5>
                        <ul>
                            <li>Name : {data.name}</li>
                            <li>Type : {data.type}</li>
                            <li>Sector Type : {data.sector_type}</li>
                            <li>Address : {data.address ?? '-'}</li>
                            <li>Phone : {data.phone ?? '-'}</li>
                            <li>E-mail : {data.email ?? '-'}</li>
                            <li>Bank Name : {data.bank_full_name ?? '-'}</li>
                            <li>Bank Account : {data.account ?? '-'}</li>
                        </ul>

                    </div>
                    <Divider layout="vertical" />
                    <div className="col-6">
                        <h5>Partner Reports</h5>
                        <ul>
                            <li>Referral Codes : {referralLink(data)}</li>
                            <li>Promotions : {promotionLink(data)}</li>
                            <li>Signups : {userLink(data)}</li>
                            <li>Transactions : {totalAmount(data)}</li>
                            <li>UCO (liter) : {roundedVol(data)}</li>
                        </ul>

                    </div>
                </div>
            </div>
        );
    };

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Partner Name', body: partnerName, sortable: true },
        { field: 'type', header: 'Type', body: getAbbreviation },
        { field: 'referral_codes', header: 'Referral Codes', body: referralLink, sortable: true },
        { field: 'promotions', header: 'Promotions', body: promotionLink, sortable: true },
        { field: 'signups', header: 'Signups', body: userLink, sortable: true },
        { field: 'totalAmount', header: 'Transactions', body: totalAmount, sortable: true },
        { field: 'totalVolume', header: 'UCO (liter)', body: roundedVol, sortable: true },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={6} xs={12} className="d-flex flex-row align-items-center gap-3">

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            <Col md={5} xs={8} className="mb-2">
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name / Type" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={4} className="mb-2">
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <PartnerExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            // lazy
                            removableSort
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            // onPage={onPage}
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                            defaultSortOrder={-1}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column sortable={col.sortable} key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default UserPartnerTable
