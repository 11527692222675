import React, { useState } from "react";

import {
    Button, Input, Modal, ModalBody, ModalHeader, Col, Row,
    Dropdown,
    DropdownMenu,
    DropdownItem, DropdownToggle,
} from "reactstrap";
import { countryList } from "constants/value";

const CountryFilter = ({ selectedFilter, setSelectedFilter, field='currency', justifyContent='end' ,disabled = false }) => {

    const [btnsecondary1, setBtnsecondary1] = useState(false);
    const selectedCountry = countryList.find(item => item[field] === selectedFilter);

    const selectedName = selectedCountry ? (
        <span className="d-flex align-items-center" style={{ display: '', alignItems: '' }}>
            <img src={selectedCountry.flagUrl} alt={`${selectedCountry.label} flag`} style={{ width: '20px', marginRight: '8px' }} />
            {selectedCountry.label}
            <i className="mdi mdi-chevron-down text-right" />
        </span>
    ) : null;
    const changeFilter = (e) => {
        setSelectedFilter(e[field]);
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-between mb-3">
                <div className={`d-flex justify-content-${justifyContent}`}>
                    <div className="d-flex align-items-center">

                        <Dropdown
                            isOpen={btnsecondary1}
                            toggle={() => setBtnsecondary1(!btnsecondary1)}
                            onChange={changeFilter}
                            disabled={disabled}
                        >
                            <DropdownToggle caret tag="button" className="btn btn-primary" type="button" >
                                {selectedName} 
                            </DropdownToggle>
                            <DropdownMenu>
                                {countryList.map((option, index) => (
                                    <DropdownItem key={index} onClick={() => changeFilter(option)}>
                                        <img src={option.flagUrl} alt={`${option.label} flag`} style={{ width: '20px', marginRight: '8px' }} />
                                        {option.label}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                    </div>
                </div>
            </div>
        </>
    )

}

export default CountryFilter