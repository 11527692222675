import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePayment from "hooks/usePayment";
import moment from "moment";
import { Button as PButton, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';
import { set } from "lodash";

const ExportCSV = ({ lazyState }) => {
    
    const { selectedPeriodName } = usePayment();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedAnonymous, setSelectedAnonymous] = useState(true)
    const [fieldList, setFieldList] = useState([]);
    const [headerFilter, setHeaderFilter] = useState([]);
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
        setFieldList([]);
    };

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Payment_Request_List_${lazyState.status}_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${selectedPeriodName}.csv`

    const headers = [
        { label: "Payment Request ID", key: "reqId" },
        { label: "User ID", key: "user_id" },
        { label: "User Name", key: "name" },
        { label: "Input Date Time", key: "createdAt" },
        { label: "Amount", key: "amount" },
        { label: "Currency", key: "currency" },
        { label: "Status", key: "status" },
        { label: "Method", key: "method" },
        { label: "Type", key: "type" },
        { label: "Account", key: "account" },
        { label: "Method Transaction ID", key: "mtId" },
        { label: "User Email", key: "email" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty or headers are not set')
        }
    }, [refresh]);

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/finance/export-payment-request`, {
                params: {
                    status: lazyState.status,
                    searchText: lazyState.searchText,
                    startDate: lazyState.searchText === '' ? lazyState.startDate : '',
                    endDate: lazyState.searchText === '' ? lazyState.endDate : '',
                    isAnonymize: selectedAnonymous
                }
            });

            const result = response.data.result;
            result.forEach(obj => {
                obj.name = obj?.partner[0]?.payment?.bank_account_name || obj?.user[0]?.name || "deleted user"
                obj.email = obj?.partner[0]?.contact?.contact_email ||obj?.user[0]?.email || "-";
                obj.createdAt = moment(obj.createdAt).format('MM/DD/YYYY HH:mm:ss');
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const updateFieldList = (value) => {
        setFieldList(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
        handleSelectAllValue();
    };

    const handleSelectAllValue = () => {
        var status = true;
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                status = false;
            }
        });
        const selectAllCheckbox = document.getElementById('select-all');
        selectAllCheckbox.checked = status;
    };

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setFieldList([]);
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
            updateFieldList(checkbox.value);
        });

    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const filteredHeaders = headers.filter(header =>
                    fieldList.includes(header.key)
                );
                setHeaderFilter(filteredHeaders);
                await fetchDataReq();
                tog_center();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => tog_center()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headerFilter}
            >
            </CSVLink>
            <Modal isOpen={modal_center} toggle={tog_center} size="md" centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    <Label>Export as CSV</Label>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Export Field</Label>
                            <FormGroup check>
                                <Input
                                    id="select-all"
                                    name="select-all"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                />
                                <Label htmlFor="select-all">Select All</Label>
                            </FormGroup>

                            <FormGroup className="d-flex" style={{ flexDirection: 'column' }}>
                                {headers?.map((header) => (
                                    <FormGroup check key={header.key}>
                                        <Input id={header.key} name="header[]" type="checkbox" value={`${header.key}`} onChange={(e) => {
                                            const clickedValue = e.target.value;
                                            updateFieldList(clickedValue);
                                        }} />
                                        <Label htmlFor={header.key}>{header.label}</Label>
                                        {validation.touched.header && validation.errors.header ? (
                                            <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        {
                            <div className="mb-3">
                                <Label className="form-label">Anonymous</Label><br />
                                <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                            </div>
                        }
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <PButton type="submit" color="primary" disabled={validation.isSubmitting || fieldList.length === 0}>
                                Submit
                            </PButton>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>

    )
}

export default ExportCSV