import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Avatar } from 'primereact/avatar';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import LoadingOverlay from 'react-loading-overlay';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import ConfirmationModal from "../modals/ConfirmationModal";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import BoxCollectionPoint from "./BoxCollectionPoint";
import PhoneNumberInput from "./PhoneNumberInput";
import dashImage from "../../../../assets/images/pickup/dash.png";
import grabImage from "../../../../assets/images/pickup/grab.png";
import { Link } from "react-router-dom";

const PickupSettings = () => {
    const { showToast } = useAuth();
    const [refresh, setRefresh] = useState(null);
    const [pickupAvailable, setPickupAvailable] = useState(false);
    const [dashPickupAvailable, setDashPickupAvailable] = useState(false);
    const [dashFixedPriceEnabled , setDashFixedPriceEnabled] = useState(false);
    const axiosApi = useAxiosPrivate();
    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rawData, setRawData] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        global: { matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    
    const [pickup, setPickup] = useState({
        quantity: {
            min: 0,
            max: 0,
        },
        distance: {
            min: 0,
            max: 0,
        },
        balance: {
            min: {
                IDR: 0,
                SGD: 0,
            }
        },
    })

    const onSearchTextChange = (e) => {
        const filterObject = { ...filters };
        filterObject.name.value = e.target.value;
        setFilters(filterObject);
        setSearch(e.target.value);
        // setPartnerLoading(true);
        // debouncedSearch(value);
    }

    const [dashPickup, setDashPickup] = useState({
        quantity: {
            min: 0,
            max: 0,
        },
        distance: {
            min: 0,
            max: 0,
        },
        balance: {
            min: {
                IDR: 0,
                SGD: 0,
            }
        },
        fixed_price:0
    })




    const getBoxes = async () => {
        // if(isSuccess) return;
        setIsSuccess(false);

        try {
            // setLoading(true)
            const response = await axiosApi.get('/api/admin/engineer/getActiveBoxes');
            setDatas(response.data);
            setIsSuccess(true);

            // setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const boxNameLink = (data) => {
        return (
            <Link
                to={`/deployments/${data.id}`}
                state={{
                    boxName: data.name,
                    boxId: data.id
                }}
            >
                {data.name}
            </Link>
        )
    }

    const transactionFormat = (data,platform) => {
        return <BoxCollectionPoint data={data} platform={platform ?? "grab"}></BoxCollectionPoint>
    }

    const tfGrab = (data) => {
        return transactionFormat(data,"grab")
    }

    const tfDash = (data) => {
        return transactionFormat(data, "dash")
    }

    const phoneNumberFormat = (data) => {
        return <PhoneNumberInput data={data}></PhoneNumberInput>
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Name', body: boxNameLink, style: { minWidth: '8rem' } },
        { field: 'phoneNumber', header: 'Phone Number', body: phoneNumberFormat, style: {padding:10,width:250} },
        { field: 'collectionPoint', header: "Grab" , body: tfGrab, style: {} },
        { field: 'dashCollectionPoint', header: "Dash" , body: tfDash, style: {} },
    ]

  
    const getAppSettings = async () => {
        try {
            const response = await axiosApi.get('/api/admin/setting/get-app-settings');
            if (response.data.setting) {
                const { setting: { pickup ,pickup_dash} } = response.data;
                if (pickup) {
                    setPickup(pickup)
                    setDashPickup(pickup_dash)
                    setPickupAvailable(pickup.service_available ?? false)
                    setDashPickupAvailable(pickup_dash.service_available ?? false)
                    setDashFixedPriceEnabled(pickup_dash.fixed_price_enabled ?? false);
                    const defaultData = {}
                    defaultData.pickup = pickup;
                    defaultData.pickup_dash = pickup_dash
                    setRawData(defaultData)

                }
            }
            // setIsSuccess(true);

        } catch (error) {

            if (error.response && error.response.data && error.response.data.state) {
                let response = error.response.data;
                response.state = "error";
                response.toast = true;
                showToast(response);
                // setIsSuccess(true);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }

        }
    }

    useEffect(() => {
        setTimeout(() => {
            getAppSettings()
            getBoxes()
        }, 300);
    }, [refresh]);

    const handleConfirm = async () => {
        try {
            const values = modalData

            const { min_qty, max_qty, max_distance, min_balance_idr, min_balance_sgd ,dash_min_qty, dash_max_qty, dash_max_distance, dash_min_balance_idr, dash_min_balance_sgd ,dash_fixed_price } = values;

            const pickup = {
                quantity: {
                    min: parseFloat(min_qty),
                    max: parseFloat(max_qty)
                },
                distance: {
                    // min: parseFloat(min_distance),
                    max: parseFloat(max_distance)
                },
                balance: {
                    min: {
                        IDR: parseFloat(min_balance_idr),
                        SGD: parseFloat(min_balance_sgd),
                    }
                }
                ,
                service_available: pickupAvailable
            }

            const dashPickup = {
                quantity: {
                    min: parseFloat(dash_min_qty),
                    max: parseFloat(dash_max_qty)
                },
                distance: {
                    // min: parseFloat(min_distance),
                    max: parseFloat(dash_max_distance)
                },
                balance: {
                    min: {
                        IDR: parseFloat(dash_min_balance_idr),
                        SGD: parseFloat(dash_min_balance_sgd),
                    }
                }
                ,fixed_price:dash_fixed_price,
                fixed_price_enabled:dashFixedPriceEnabled,
                service_available: dashPickupAvailable
            }

            const obj = { pickup: pickup,pickup_dash:dashPickup }

            const response = await axiosApi.post('/api/admin/setting/update-pickup-settings', obj)
            setRefresh(response.data.random)
            showToast(response.data);
            setIsModalOpen(false)

        } catch (error) {
            if (error.response && error.response.data) {
                validation.setErrors(error.response.data);
            } else {
                console.error(error);
            }
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setModalData(null)
    }

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

            min_qty: String(pickup.quantity.min),
            // min_distance: String(pickup.distance.min),
            max_qty: String(pickup.quantity.max),
            max_distance: String(pickup.distance.max),
            min_balance_idr: String(pickup.balance.min.IDR),
            min_balance_sgd: String(pickup.balance.min.SGD),

            dash_min_qty: String(dashPickup?.quantity?.min ?? 0),
            dash_max_qty: String(dashPickup?.quantity?.max ?? 0),
            dash_max_distance: String(dashPickup?.distance?.max ?? 0),
            dash_min_balance_idr: String(dashPickup?.balance?.min?.IDR ?? 0),
            dash_min_balance_sgd: String(dashPickup?.balance?.min?.SGD ?? 0),
            dash_fixed_price: String(dashPickup?.fixed_price ?? 0),

        },
        validationSchema: Yup.object().shape({

            min_qty: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
                .required('This field is required'),
            // min_distance: Yup.string()
            //     .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
            //     .required('This field is required'),
            max_qty: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
                .required('This field is required'),
            max_distance: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
                .required('This field is required'),

        }),
        onSubmit: async (values, { resetForm }) => {
            setIsModalOpen(true)
            const opts = { ...values, pickupAvailable: pickupAvailable ,  dashPickupAvailable: dashPickupAvailable ,dash_fixed_price_enabled : dashFixedPriceEnabled}
            setModalData(opts)
        },
    });

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <LoadingOverlay
                        active={!isSuccess}
                        spinner
                        text='Loading your content...'
                    >
                    <Card>
                        <CardBody>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    <Col xl={3}>
                                        <div className="mb-5">
                                        <Accordion activeIndex={0}>
                                            <AccordionTab
                                                   header={
                                                    <div className="custom-header-accordion-pickup">
                                                        <Avatar image={dashImage} />
                                                        <span className="font-bold white-space-nowrap">Dash</span>
                                                    </div>
                                                }
                                                    id="dashSettings"
                                                >
                                                                      <div className="mb-1">
                                            <p className="mb-1">Enable Service</p>
                                            <InputSwitch checked={dashPickupAvailable} onChange={(e) => setDashPickupAvailable(e.value)} />
                                        </div>
                                            <p className="mb-1">Minimum Balance :</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">IDR</Label>
                                                        <Input
                                                            name="dash_min_balance_idr"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_min_balance_idr || ""}
                                                            invalid={
                                                                validation.touched.dash_min_balance_idr && validation.errors.dash_min_balance_idr ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_balance_idr && validation.errors.min_balance_idr ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_balance_idr}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">SGD</Label>
                                                        <Input
                                                            name="dash_min_balance_sgd"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_min_balance_sgd || ""}
                                                            invalid={
                                                                validation.touched.dash_min_balance_sgd && validation.errors.dash_min_balance_sgd ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_balance_sgd && validation.errors.min_balance_sgd ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_balance_sgd}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                                <p className="mb-1">Quantity (liter) :</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">Minimum</Label>
                                                        <Input
                                                            name="dash_min_qty"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_min_qty || ""}
                                                            invalid={
                                                                validation.touched.dash_min_qty && validation.errors.dash_min_qty ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.dash_min_qty && validation.errors.dash_min_qty ? (
                                                                <FormFeedback type="invalid">{validation.errors.dash_min_qty}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">Maximum</Label>
                                                        <Input
                                                            name="dash_max_qty"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_max_qty || ""}
                                                            invalid={
                                                                validation.touched.dash_max_qty && validation.errors.dash_max_qty ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.max_qty && validation.errors.max_qty ? (
                                                                <FormFeedback type="invalid">{validation.errors.max_qty}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                                <p className="mb-1">Maximum Distance (km):</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Input
                                                            name="dash_max_distance"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_max_distance || ""}
                                                            invalid={
                                                                validation.touched.dash_max_distance && validation.errors.dash_max_distance ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.dash_max_distance && validation.errors.dash_max_distance ? (
                                                                <FormFeedback type="invalid">{validation.errors.dash_max_distance}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>

                                                <p className="mb-1">Enable Fixed Price:</p>
                                                <InputSwitch checked={dashFixedPriceEnabled} onChange={(e) => setDashFixedPriceEnabled(e.value)} />
                                              

                                                {dashFixedPriceEnabled && 
                                                <>
                                                <p className="mb-1">Fixed Price (IDR):</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Input
                                                            name="dash_fixed_price"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dash_fixed_price || ""}
                                                            invalid={
                                                                validation.touched.dash_fixed_price && validation.errors.dash_fixed_price ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.dash_fixed_price && validation.errors.dash_fixed_price ? (
                                                                <FormFeedback type="invalid">{validation.errors.dash_fixed_price}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                                </>
                                                }
                                            </AccordionTab>
                                           
                                            <AccordionTab    
                                             header={
                                                    <div className="custom-header-accordion-pickup">
                                                        <Avatar image={grabImage} />
                                                        <span className="font-bold white-space-nowrap">Grab</span>
                                                    </div>
                                                } id="grabSettings">
                                                         <div className="mb-1">
                                            <p className="mb-1">Enable Service</p>
                                            <InputSwitch checked={pickupAvailable} onChange={(e) => setPickupAvailable(e.value)} />
                                        </div>
                                                <p className="mb-1">Minimum Balance :</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">IDR</Label>
                                                        <Input
                                                            name="min_balance_idr"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.min_balance_idr || ""}
                                                            invalid={
                                                                validation.touched.min_balance_idr && validation.errors.min_balance_idr ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_balance_idr && validation.errors.min_balance_idr ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_balance_idr}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">SGD</Label>
                                                        <Input
                                                            name="min_balance_sgd"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.min_balance_sgd || ""}
                                                            invalid={
                                                                validation.touched.min_balance_sgd && validation.errors.min_balance_sgd ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_balance_sgd && validation.errors.min_balance_sgd ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_balance_sgd}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                                <p className="mb-1">Quantity (liter) :</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">Minimum</Label>
                                                        <Input
                                                            name="min_qty"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.min_qty || ""}
                                                            invalid={
                                                                validation.touched.min_qty && validation.errors.min_qty ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_qty && validation.errors.min_qty ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_qty}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <Label className="form-label">Maximum</Label>
                                                        <Input
                                                            name="max_qty"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.max_qty || ""}
                                                            invalid={
                                                                validation.touched.max_qty && validation.errors.max_qty ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.max_qty && validation.errors.max_qty ? (
                                                                <FormFeedback type="invalid">{validation.errors.max_qty}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                                <p className="mb-1">Maximum Distance (km):</p>
                                                <ul className="list-inline d-flex align-items-start">
                                                    {/* <li className="list-inline-item">
                                                        <Label className="form-label">Minimum</Label>
                                                        <Input
                                                            name="min_distance"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.min_distance || ""}
                                                            invalid={
                                                                validation.touched.min_distance && validation.errors.min_distance ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.min_distance && validation.errors.min_distance ? (
                                                                <FormFeedback type="invalid">{validation.errors.min_distance}</FormFeedback>
                                                            ) : null}
                                                    </li> */}
                                                    <li className="list-inline-item">
                                                        <Input
                                                            name="max_distance"
                                                            placeholder="Enter Value..."
                                                            type="text"
                                                            autoComplete="off"
                                                            inputMode="numeric"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.max_distance || ""}
                                                            invalid={
                                                                validation.touched.max_distance && validation.errors.max_distance ? true : false
                                                            }
                                                        />
                                                        {
                                                            validation.touched.max_distance && validation.errors.max_distance ? (
                                                                <FormFeedback type="invalid">{validation.errors.max_distance}</FormFeedback>
                                                            ) : null}
                                                    </li>
                                                </ul>
                                           

                                                </AccordionTab>
                                                </Accordion>
                                        </div>


                             

                                       
                                    </Col>
                                    <Col xl={9}>
                                    <Row className="mb-1">
                                        <Col xs={6}>
                                        <h4 class="card-title mb-0 py-2">UCOllect Boxes</h4>
                                        </Col>
                                        <Col xs={6}>
                                        <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Box Name" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                                </span>
                                        </Col>
                                    
                                    </Row>
                                        <DataTable
                                            filters={filters}
                                            globalFilterFields={['name']}
                                            value={loading ? items : datas}
                                            stripedRows
                                            showGridlines
                                            size={'normal'}
                                            metaKeySelection={true}
                                            selectionMode={'single'}
                                            selection={loading ? '' : datas}
                                            scrollable
                                            scrollHeight='439px'
                                        >
                                            
                                            {columns.map((col, i) => (
                                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
                                            ))}
                                        </DataTable>
                                        <br></br>
                                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                                            <Button type="submit" className="btn btn-lg" color="primary" disabled={validation.isSubmitting}>
                                            <i class="fas fa-save"></i> {validation.isSubmitting ? 'Submitting...' : 'Save'}
                                            </Button>{" "}
                                        </div>
                                    </Col>
                           
                                </Row>
                         
                            </Form>

                        </CardBody>
                    </Card>
                    </LoadingOverlay>

                </Col>
            </Row>
            {isModalOpen && (
                <ConfirmationModal
                    isOpen={isModalOpen}
                    toggle={handleCancel}
                    onConfirm={handleConfirm}
                    data={modalData}
                    type={"pickup"}
                    rawData={rawData}
                />
            )}
        </React.Fragment>
    );
};

export default PickupSettings;
