import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import useUser from "hooks/useUser";
import { Button as PButton, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';

const UserExportCSV = ({lazyState}) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedAnonymous, setSelectedAnonymous] = useState(true);
    const [fieldList, setFieldList] = useState([]);
    const [headerFilter, setHeaderFilter] = useState([]);
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { selectedCurrency } = useUser();

    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
        setFieldList([]);
    };

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Users_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { label: "ID", key: "_id" },
        { label: "Name", key: "name" },
        { label: "Username", key: "username" },
        { label: "Address", key: "address" },
        { label: "City", key: "city" },
        { label: "Country", key: "country" },
        { label: "Postcode", key: "postcode" },
        { label: "Phone", key: "phone.number" },
        { label: "Currency", key: "currency" },
        { label: "Balance", key: "balance" },
        { label: "Joined", key: "createdAt" },
        { label: "Accepted UCO (liter)", key: "trans_status.accepted"},
        { label: "Rejected UCO (liter)", key: "trans_status.rejected"},
        { label: "Total Request", key: "countPayments" },
        { label: "QR Hash", key: "qr_hash" },
        { label: "Referral", key: "referral_code" },
        { label: "isActive", key: "active" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const updateFieldList = (value) => {
        setFieldList(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
        handleSelectAllValue();
    };

    const handleSelectAllValue = () => {
        var status = true;
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                status = false;
            }
        });
        const selectAllCheckbox = document.getElementById('select-all');
        selectAllCheckbox.checked = status;
    };

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setFieldList([]);
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
            updateFieldList(checkbox.value);
        });

    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const filteredHeaders = headers.filter(header =>
                    fieldList.includes(header.key)
                );
                setHeaderFilter(filteredHeaders);
                await fetchDataReq();
                tog_center();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const queryParams = {
                searchText: lazyState.searchText || '', // Default to empty string if not present
                searchUserMethod: lazyState.searchUserMethod || '',
                searchMode: lazyState.searchMode || '', // Default to empty string if not present
                startDate: lazyState.startDate || '', // Default to empty string if not present
                endDate: lazyState.endDate || '', // Default to empty string if not present
                currency: selectedCurrency || '', // Default to empty string if not present
                isAnonymize: selectedAnonymous
            };

            // Apply conditions dynamically
            if (!lazyState.startDate) delete queryParams.startDate; // Remove startDate if not set
            if (!lazyState.endDate) delete queryParams.endDate;     // Remove endDate if not set
            if (lazyState.searchMode == 'all') delete queryParams.searchMode;     // Remove searchMode if not set

            // Make the API call
            const response = await axiosApi.get('/api/admin/support/getUsers', { params: queryParams });
            const result = response.data.users;
            result.forEach(obj => {
                obj.trans_status.accepted = simplify(obj.trans_status.accepted);
                obj.trans_status.rejected = simplify(obj.trans_status.rejected);
                obj.referral_code = obj.referral_code??'-';

            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => tog_center()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headerFilter}
            >
            </CSVLink>

            <Modal isOpen={modal_center} toggle={tog_center} size="md" centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    <Label>Export as CSV</Label>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Export Field</Label>
                            <FormGroup check>
                                <Input
                                    id="select-all"
                                    name="select-all"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                />
                                <Label htmlFor="select-all">Select All</Label>
                            </FormGroup>

                            <FormGroup className="d-flex flex-wrap">
                                {headers?.map((header) => (
                                    <FormGroup check key={header.key} style={{ flex: '0 0 50%' }}>
                                        <Input id={header.key} name="header[]" type="checkbox" value={`${header.key}`} onChange={(e) => {
                                            const clickedValue = e.target.value;
                                            updateFieldList(clickedValue);
                                        }} />
                                        <Label htmlFor={header.key}>{header.label}</Label>
                                        {validation.touched.header && validation.errors.header ? (
                                            <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                ))}
                            </FormGroup>
                        </div>
                        {
                            <div className="mb-3">
                                <Label className="form-label">Anonymous</Label><br />
                                <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                            </div>
                        }
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <PButton type="submit" color="primary" disabled={validation.isSubmitting || fieldList.length === 0}>
                                Submit
                            </PButton>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
        
    )
}

export default UserExportCSV