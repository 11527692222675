import { useState, useRef, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';

import { simplify, startTime, endTime, debounce, balanceFormatter, currencyFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import usePartner from "hooks/usePartner";
import EditData from "./modals/EditData";
import DeleteData from "./modals/DeleteData";
import { formatThousandSeparator } from "helpers/functions";
import '../../../assets/css/style.css'
import TransactionHistoryModal from "./modals/TransactionHistoryModal";
import SortHeader from "pages/SortHeader";

const LocationPartnersTable = ({ id, searchId, lazyState, setlazyState, setSortField }) => {
    const [search, setSearch] = useState(id || searchId || '')
    const [sortStates, setSortStates] = useState({
        total_collected_volume: 'idle',
        locations: 'idle',
        share_ratio: 'idle'
    });
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState

    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    const { dataTotalRecords, setDataLoading, dataLoading, dataTable } = usePartner();
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 1000;


    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setDataLoading(true);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const badgeDirectPayment = (data) => {
        let badgeText = data.direct_payment_enabled ? "ENABLED" : "DISABLED";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.direct_payment_enabled === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const badgeBonus = (data) => {
        let badgeText = data.bonus_enabled ? "Yes" : "No";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.bonus_enabled === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }

    const fixedPrice = (data) => {
        if (data.is_fixed_price) {

            return balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR');
        } else {
            return (
                <>
                    {balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR')}
                    <br />
                    <span className="badge bg-primary text-t-primary">
                        App Price
                    </span>
                </>
            );

        }
    }

    const partnerName = (data) => {
        return <Link to={`/partner-overview/${data?.partner_account}`}> {data?.partner_account} </Link>
    }

    const collectedVolume = (data) => {
        // return data.detail.total_collected_volume || 0;
        return <TransactionHistoryModal rowData={data} options={lazyState} type="custom" />
    }
    const locationCount = (data) => {
        return data?.detail?.box_ids?.length || 0
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditData rowData={data} />
                </div>
                {/* <div>
                    <DeleteData rowData={data} />
                </div> */}
            </div>
        )
    }
   
    const headerTotalVolume = () => {
        return <SortHeader   title={ <span>Total Collected<br/> Volume</span>} sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'total_collected_volume'} />;
    }
    const headerLocation = () => {
        return <SortHeader title="Locations" sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'locations'}  />;
    }
    const headerShareRatio = () => {
        return <SortHeader title='Share Ratio' sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'share_ratio'} />;
    }

    
    // const headerLocation = () => {
    //     return (
    //         <div style={{ display: 'block', width: '100%', height: '100%', cursor: 'pointer' }}
    //             onClick={handleClick}>
    //             <div>Total Collected</div><div> Volume</div> &nbsp;
    //             {renderSortIcon(sortStates)}
    //         </div>
    //     );
    // }
   
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Partner Name', body: partnerName, className: '' },
        { field: 'total_collection_volume', header: (<span>Total Collected<br/> Volume</span>), body: collectedVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' },sortable:true },
        { field: 'detail.box_ids.length', header: "Locations", body: locationCount, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'share_ratio', header: "Share Ratio", body: '', className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'total_share', header: 'Total Share', body: '', className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                            </Col>
                            <Col md={5} xs={8} className="mb-2">
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={4} className="mb-2">
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>


                        <DataTable
                            // lazy
                            removableSort
                            value={dataLoading ? items : dataTable}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            // onPage={onPage}
                            totalRecords={dataTotalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={dataLoading ? '' : '_id'}
                            defaultSortOrder={-1}
                        >
                            {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    sortable={col.sortable}
                                    field={col.field}
                                    header={col.header}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                    body={dataLoading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                    
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default LocationPartnersTable